import { ProfileView } from "authory-api-types/dist/enums";

export const getV3ContentDimension = (viewType: ProfileView, isAboutPage: boolean) => {
    if (isAboutPage) return 760;

    switch (viewType) {
        case ProfileView.TwoColumnThumbnail:
            return 1032;
        case ProfileView.OneColumnThumbnail:
            return 760;
        case ProfileView.List:
        case ProfileView.Text:
            return 816;
        default:
            return 0;
    }
}