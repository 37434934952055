import styled from "styled-components";
import ContentEditable from "react-contenteditable";
import { media, theme } from "../../theme/theme";
import { V3HeadlineH1, V3Paragraph } from "../Typography";
import { V3ProfileHeroColorVariants } from "./V3ProfileHero.types";
import { ProfileView, UserProfileStyle } from "authory-api-types/dist/enums";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";
import { getV3ContentDimension } from "../../utils/getV3ContentDimension";
import { EmbedMode } from "../../utils/embeds";

type V4PHImageContainerProps = {
    $desktopSrc?: string
    $mobileSrc?: string
    $useImage: boolean
    $coverColor?: string,
    $enforceMinHeight: boolean,
    $isAboutPage: boolean,
};

export const V4PHMainWrapper = styled.div`
    padding: 0 18px;
`;

export const V4PHImageContainer = styled.div.attrs<V4PHImageContainerProps>(
    ({ $coverColor }) => ({
        style: {
            backgroundColor: $coverColor ? $coverColor : "white"
        },
    })
) <V4PHImageContainerProps>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    min-height: ${({ $enforceMinHeight, $isAboutPage }) => {
        if ($isAboutPage) return undefined;
        return $enforceMinHeight ? "400px" : undefined;
    }};
    background-image: url(${({ $mobileSrc, $useImage }) => $useImage && $mobileSrc ? $mobileSrc : null});

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        min-height: ${({ $isAboutPage }) => {
        if ($isAboutPage) return "250px";
    }};
    }

    &:before {
        display: ${({ $useImage }) => $useImage ? "block" : "none"};
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(8, 22, 43, 0.6);
    }

    ${media.custom(theme.breakpoints.phone)} {
        background-image: url(${({ $desktopSrc, $useImage }) => $useImage && $desktopSrc ? $desktopSrc : undefined});
    }
`;

export const V4PHContentWrapper = styled.div`
    position: relative;
`;

export const V4PHTopBar = styled.div<{ $profileStyle: UserProfileStyle, $smallPadding: boolean, $editMode?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ $smallPadding, $profileStyle }) => $smallPadding ? "23px 0px" : $profileStyle === UserProfileStyle.centered ? "23px 0px 0px" : "23px 0 0 0"};

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding: ${({ $smallPadding, $profileStyle }) => $smallPadding ? "23px 0px" : $profileStyle === UserProfileStyle.centered ? "23px 0px 40px" : "23px 0 75px 0"};
    }

    > div {
        &:first-child {
            margin-right: 25px;
            flex-grow: 1;
        }
    }
`;

export const PROFILE_HERO_PAD = 80;

export const V4PHContentMainWrapper = styled.div<{ $viewType: ProfileView, $profileStyle: UserProfileStyle, $isAboutPage: boolean }>`
    max-width: ${({ $viewType, $profileStyle, $isAboutPage }) => $profileStyle === UserProfileStyle.centered ? "771px" : `${getV3ContentDimension($viewType, $isAboutPage) + PROFILE_HERO_PAD}px`};
    padding-bottom: 55px;
    margin: 0 auto;
    padding-top: 40px;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding-top: 0;
        padding-bottom: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.centered ? "100px" : "130px"}; 
    }
`;

export const V4PHAuthorMetaWrapper = styled.div<{ $colorVariant: V3ProfileHeroColorVariants, $profileStyle: UserProfileStyle }>`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    word-break: break-word;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        flex-direction: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.centered ? "column" : "row"};
        text-align: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.centered ? "center" : "left"};  
        align-items: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.leftAligned ? "center" : undefined}; 
    }

    ${V3HeadlineH1},
    ${V3Paragraph} {
        color: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.ink};
    }

    > div {
        &:first-child {
            margin-bottom: 12px;

            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                margin-right: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.leftAligned ? "55px" : undefined};
            }
        }
    }
`;

export const V4PHProfileAvatarWrapper = styled.div<{ $profileStyle: UserProfileStyle, $isColorWhite: boolean }>`
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ $isColorWhite }) => !$isColorWhite ? "rgba(255, 255, 255, 0.30)" : "rgba(0, 0, 0, 0.30)"}; ;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    border: 3px solid white;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        width: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.centered ? "120px" : "220px"};
        height: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.centered ? "120px" : "220px"};
    }
`;

export const V4PHProfileAvatarImage = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; 
    right: 0;
`;

export const LegacyProfileElem = styled.div<{ $colorVariant: V3ProfileHeroColorVariants, $dashOnHover: boolean, $legacyEditingExperience: boolean, $editMode: boolean }>`
    padding: 4px;
    cursor: ${({ $legacyEditingExperience }) => $legacyEditingExperience ? "pointer" : undefined};

    &:hover {
        outline: ${({ $editMode, $legacyEditingExperience, $colorVariant }) => (!$editMode || !$legacyEditingExperience) ? undefined : $colorVariant === V3ProfileHeroColorVariants.light ? " 1px dashed  white" : "1px dashed rgba(110, 118, 135, 0.30)"};
        border-radius: 8px;
    }
`;

export const ProfileContentEditable = styled(ContentEditable) <{ $colorVariant: V3ProfileHeroColorVariants, $dashOnHover: boolean, $legacyEditingExperience?: boolean, $editMode?: boolean, placeholder: string }>`
    padding: 4px;
    cursor: ${({ $legacyEditingExperience }) => $legacyEditingExperience ? "pointer" : undefined};

    &:hover {
        outline: ${({ $editMode, $legacyEditingExperience, $colorVariant }) => (!$editMode || !$legacyEditingExperience) ? undefined : $colorVariant === V3ProfileHeroColorVariants.light ? " 1px dashed  white" : "1px dashed rgba(110, 118, 135, 0.30)"};
        border-radius: 8px;
    }

    &[contenteditable="true"] {
        cursor: text;
        outline: 1px dashed ${({ $colorVariant }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : "rgba(110, 118, 135, 0.30)"};
        border-radius: 8px;
        display: inline-block;

        &:focus {
            outline: 1px dashed ${({ $colorVariant }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : "rgba(110, 118, 135, 0.30)"};
        }

        &:empty:before {
            content: attr(placeholder);
            display: block;
            color: ${({ $colorVariant }) => $colorVariant === V3ProfileHeroColorVariants.light ? "rgba(255, 255, 255, 0.30)" : "#aaa"};
        }

        ${media.custom(theme.breakpoints.phone)} {
            outline: 1px dashed ${({ $colorVariant, $dashOnHover }) => $dashOnHover ? "rgba(110, 118, 135, 0)" : $colorVariant === V3ProfileHeroColorVariants.light ? "white" : "rgba(110, 118, 135, 0.30)"};

            &:hover {
                outline: 1px dashed ${({ $colorVariant }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : "rgba(110, 118, 135, 0.30)"};
            }
        }
    }
`;

export const MobileSocialsWrapper = styled.div`
    display: flex;
    justify-content: center;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        display: none;
    }
`;

export const ProfileSocialsContainer = styled.div<{ $editMode?: boolean, $isEmbed?: boolean, $EmbedMode?: EmbedMode, $desktopSideMargin?: boolean }>`
    display: flex;
    align-items: center;
    flex-wrap: ${({ $isEmbed }) => !$isEmbed ? "wrap" : "nowrap"};
    justify-content: ${({ $isEmbed, $EmbedMode }) => !$isEmbed || $EmbedMode === EmbedMode.normal ? "center" : "row"};
    flex-direction: ${({ $isEmbed }) => !$isEmbed ? "column" : "row"};

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
       flex-direction: row;
    }

    > div {
        &:first-child {
            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                margin-right: ${({ $desktopSideMargin }) => $desktopSideMargin ? "12px" : undefined};
            }
        }

        &:nth-child(2) {
            margin-top: 32px;
            
            ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
                margin-top: 0;
                margin-left: ${({ $editMode }) => $editMode ? undefined : "12px"};
            }
        }
    }
`;

export const ModalMetadata = styled.div`
    text-align: center;
  
    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding: 0 28px;
    }

    ${V3Paragraph} {
        color:  ${({ theme }) => theme.v3.colors.grey35};
    }
`;

export const CertificateModalLink = styled(InlineLink)`
    text-decoration: underline;
    color: inherit;
`;

export const CertificateProfileModalItem = styled.div`
    padding: 0 10px 6px;
`;

export const CertificatesItemsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    > a {
        display: block;
    }
`;

export const ImageAvatar = styled.div<{ background?: string, variant: UserProfileStyle, isSignupPreview?: boolean }>`
    display: inline-block;
    margin: ${({ variant }) => variant === UserProfileStyle.centered ? "0 auto" : undefined}; 
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    background:  ${({ theme }) => theme ? theme.v3.colors.lightBlue : null};
    cursor: ${({ isSignupPreview }) => !isSignupPreview ? "pointer" : undefined};
`;

export const V3ProfileName = styled(V3HeadlineH1) <{ color: V3ProfileHeroColorVariants, isSignupPreview?: boolean }>`
    color: ${({ color, theme }) => color === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.ink};
`;

export const V4HealineCertificateWrapper = styled.div<{ $editMode: boolean, $profileStyle: UserProfileStyle }>`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        justify-content: ${({ $profileStyle }) => $profileStyle === UserProfileStyle.centered ? "center" : "flex-start"};
    }

    > div {
        &:nth-child(2) {
            margin-top: ${({ $editMode }) => !$editMode ? "6px" : "5px"};
            margin-left: 10px;

            ${media.tablet} {
                margin-top: ${({ $editMode }) => !$editMode ? "12px" : "14px"};
                margin-left: 10px;
            }
            
            &:hover {
                cursor: pointer;

                svg {
                    path {
                        fill: ${({ $editMode }) => !$editMode ? "white" : undefined};
                    }
                }
            }
        }
    }
`;

export const V3SeeMore = styled.span`
    text-decoration: underline;
    margin-left: 5px;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
`;

export const AboutPageTitleWrapper = styled.div<{ $colorVariant: V3ProfileHeroColorVariants }>`
    max-width: 771px;
    padding-bottom: 40px;
    margin: 0 auto;
    padding-top: 40px;
    text-align: center;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding-top: 0;
        padding-bottom: 100px; 
    }

    ${V3HeadlineH1} {
        color: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.ink};
        margin: 0 auto;
    }
`;