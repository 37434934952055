import styled, { css } from "styled-components";
import { V3Caption } from "../Typography";
import { V3ProfileHeroColorVariants } from "./V3ProfileHero.types";
import { media, theme } from "../../theme/theme";

const sharedIconCSS = css<{ $colorVariant: V3ProfileHeroColorVariants, $noOpacity: boolean, $primary?: boolean }>`
    border: ${({ $colorVariant, $noOpacity, $primary }) => $colorVariant === V3ProfileHeroColorVariants.light ? `1px solid rgba(255, 255, 255, ${$noOpacity ? "1" : $primary ? "0.6" : "0.4"}); ` : `1px solid rgba(110, 118, 135, ${$noOpacity ? "1" : "0.2"}); `}; 
    color: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.grey35};
    background: ${({ $primary, $colorVariant, theme }) => $primary ? $colorVariant === V3ProfileHeroColorVariants.light ? "rgba(255,255,255,0.2)" : theme.v3.colors.grey88 : undefined};

    &:hover {
        cursor: pointer;
        background: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? "rgba(255,255,255,0.8)" : theme.v3.colors.grey96};
        color:  ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? theme.v3.colors.ink : theme.v3.colors.grey35};
        border:  ${({ $primary, $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? undefined : $primary ? `1px solid ${theme.v3.colors.grey70}` : `1px solid ${theme.v3.colors.grey96}`};
    }
`;

export const SocialSVG = styled.div <{ $colorVariant: V3ProfileHeroColorVariants, $noOpacity: boolean }>`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        &:not(.skip-fill) {
            path {
                fill: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.grey48};
            }
        }

        &.skip-fill {
            path {
                stroke: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.grey48};
            }
        }
    }

    &:hover {
        svg {
            rect {
                fill: ${({ $colorVariant, theme, }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.grey96};
                opacity: ${({ $colorVariant, }) => $colorVariant === V3ProfileHeroColorVariants.light ? "0.8" : undefined};
                stroke: ${({ $colorVariant, theme, }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.grey96};
                stroke-opacity: 1;
            }

            &:not(.skip-fill) {
                path {
                    fill: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? theme.v3.colors.ink : theme.v3.colors.grey35};
                }
            }

            &.skip-fill {
                path {
                    stroke: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? theme.v3.colors.ink : theme.v3.colors.grey35};
                }
            }
        }
    }

    ${sharedIconCSS}
`;

export const ContactMeButton = styled(V3Caption) <{ $colorVariant: V3ProfileHeroColorVariants, $noOpacity: boolean, $primary?: boolean }>`
    padding: 5px 16px;
    border-radius: 100px;
    background: transparent;
    width: auto;
    max-width: 250px;
    height: 36px;
    white-space: nowrap;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;

    ${sharedIconCSS}
`

export const ProfileSocialsWrapper = styled.div<{ $customJustify?: string }>`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: ${({ $customJustify }) => $customJustify || "center"}; 

    ${media.custom(theme.breakpoints.phone)} {
        flex-wrap: nowrap;
    }

    > div {
        display: inline-flex;

        ${media.custom(theme.breakpoints.phone)} {
            margin-top: 0;
        }
        
        a {
            display: inline-flex;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
`;
